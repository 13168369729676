<template>
  <sidebar-menu
    class="sidebar"
    v-if="isLogged"
    :menu="final_menu"
    :collapsed="isNavClose"
    theme="white-theme"
    :disableHover="true"
    :hideToggle="true"
    :showOneChild="true"
  >
    <template v-slot:header>
      <div :style="cssProps"></div>
      <!-- <div class="version text-sm-right">Version: 3.{{ cliVer }}</div> -->
    </template>

    <template v-slot:dropdown-icon>
      <span><b-icon icon="chevron-compact-right"></b-icon></span>
    </template>
  </sidebar-menu>
</template>

<script>
import { isAdmin } from "@/utils/permissions";
import { canVerb, canModule } from "@/utils/permissions";
import { mapGetters, mapActions } from "vuex";
// vue-sidebar-menu > https://www.npmjs.com/package/vue-sidebar-menu

export default {
  data() {
    return {
      collapsed: false,
      final_menu: [],
      menu: [
        {
          href: { name: "module.HOME" },
          title: "Home",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "house",
              style: "width: 20px; height: 20px;",
              title: "Home",
            },
          },
          disabled: false,
          menu_voice: "module.HOME",
        },
        {
          href: { name: "module.PFOLIO" },
          title: "Portafoglio",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "wallet2",
              style: "width: 20px; height: 20px;",
              title: "Portafoglio",
            },
          },
          disabled: false,
          menu_voice: "module.PFOLIO",
          menu_permissions: [
            {
              resource: "registries",
              verb: "index",
            },
            {
              resource: "registry_groups",
              verb: "index",
            },
            {
              resource: "insurance_policies",
              verb: "index",
            },
            {
              resource: "claims",
              verb: "index",
            },
            {
              resource: "agency_contacts",
              verb: "index",
            },
          ],
        },
        {
          href: { name: "module.TIT.TINS" },
          title: "Titoli",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "journals",
              style: "width: 20px; height: 20px;",
              title: "Titoli",
            },
          },
          disabled: false,
          menu_voice: "module.TIT",
          menu_permissions: [
            {
              resource: "insurance_ancillaries",
              verb: "index",
            },
          ],
        },
        {
          title: "Contabilità",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "calculator",
              style: "width: 20px; height: 20px;",
              title: "Contabilità",
            },
          },
          disabled: false,
          menu_voice: "module.CONT",
          menu_permissions: [
            {
              resource: "book_entries",
              verb: "index",
            },
            {
              resource: "agency_books",
              verb: "index",
            },
          ],
          child: [
            {
              href: { name: "module.CONT.FCAS" },
              title: "Fogli Cassa",
              menu_voice: "module.CONT",
              hideWhen: [],
            },
            {
              href: { name: "module.CONT.SOSP" },
              title: "Sospesi",
              menu_voice: "module.CONT",
              hideWhen: [],
            },
            {
              href: { name: "book_entries.index2" },
              title: "Acconti",
              menu_voice: "module.CONT",
              hideWhen: [],
            },
            {
              href: { name: "book_entries.index" },
              title: "Prospetto clienti e produttori",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.MOVV" },
              title: "Movimenti Vari",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.FQUA" },
              title: "Foglio Quadratura",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.STAT" },
              title: "Stats",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.CONP" },
              title: "Contabilità Produttori",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.CONC" },
              title: "Contabilità Compagnie",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "book_entries.index3" },
              title: "Collaborazioni orizzontali",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.COAS" },
              title: "Coassicurazioni",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.CONA" },
              title: "Contabilità Agenzia",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "module.CONT.ESCO" },
              title: "Estratti conto",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
            {
              href: { name: "movimenti_prima_nota.index0" },
              title: "Contabilità Giornaliera",
              menu_voice: "module.CONT",
              // hideWhen: ["production"],
            },
          ],
        },
        {
          href: { name: "module.QUIE.RICQ" },
          title: "Quietanzamento",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "receipt",
              style: "width: 20px; height: 20px;",
              title: "Quietanzamento",
            },
          },
          disabled: false,
          menu_voice: "module.QUIE",
          menu_permissions: [
            {
              resource: "insurance_payments",
              verb: "index",
            },
          ],
        },
        {
          title: "LisaComunica",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "chat-text",
              style: "width: 20px; height: 20px;",
              title: "Comunicazioni",
            },
          },
          disabled: false,
          menu_voice: "module.COM",
          // hideWhen: ["production"],
          child: [
            {
              href: { name: "communication_procedures.index" },
              title: "Procedure",
            },
            {
              href: { name: "lisacomunica_template" },
              title: "Modelli",
            },
            {
              href: { name: "lisacomunica_log" },
              title: "Log",
            },
          ],
        },
        {
          href: { name: "module.CLOU" },
          title: "Documenti",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "file-earmark",
              style: "width: 20px; height: 20px;",
              title: "Documenti",
            },
          },
          disabled: false,
          menu_voice: "module.CLOU",
          // hideWhen: ["production"],
          /*child: [
            {
              href: { name: "module.CLOU" },
              title: "Ricerca Documenti",
            },
          ],*/
        },
        {
          title: "Log",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "file-text",
              style: "width: 20px; height: 20px;",
              title: "Log",
            },
          },
          disabled: false,
          // menu_voice: "module.LOG",
          hidden: false,
          // hideWhen: ["production"],
          child: [
            // {
            //   href: { name: "module.LOG.LEMA" },
            //   title: "Email",
            //   menu_voice: "module.LOG",
            // },
            // {
            //   href: { name: "module.LOG.LSMS" },
            //   title: "Sms",
            //   menu_voice: "module.LOG",
            // },
            // {
            //   href: { name: "module.LOG.LIBR" },
            //   title: "Posta Ibrida",
            //   menu_voice: "module.LOG",
            // },
            {
              href: { name: "import" },
              title: "Import",
              // menu_voice: "module.LOG",
              hidden: false,
            },
            // {
            //   href: { name: "broker_statement" },
            //   title: "Rendiconti",
            //   menu_voice: "module.LOG",
            // },
          ],
        },
        /* {
          title: "Priv-Easy",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "shield-lock",
              style: "width: 20px; height: 20px;",
              title: "Priv-Easy",
            },
          },
          disabled: false,
          menu_voice: "module.PEASY",
          menu_permissions: [
            {
              resource: "registries",
              verb: "index",
            },
          ],
          child: [
            {
              href: { name: "module.PEASY.PRIV" },
              title: "Clienti",
              menu_voice: "module.PEASY",
            },
          ],
        }, */
        // {
        //   title: "Cloud",
        //   icon: {
        //     element: "b-icon",
        //     attributes: {
        //       variant: "light",
        //       icon: "cloud",
        //       style: "width: 20px; height: 20px;",
        //       title: "Cloud",
        //     },
        //   },
        //   disabled: false,
        //   menu_voice: "module.CLOU",
        // },
        {
          title: "Profilo",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "person",
              style: "width: 20px; height: 20px;",
              title: "Profilo",
            },
          },
          disabled: false,
          menu_voice: "module.PROF",
          child: [
            {
              href: { name: "bank_accounts.index" },
              title: "Banche",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "module.PROF" },
              title: "Compagnie e Prodotti",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "module.PROF.COMP" },
              title: "Comparti Contabili",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "module.PROF.CAGG" },
              title: "Forme di Pagamento",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "various_accountings.index" },
              title: "Causali contabili Movimenti Vari",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "module.PROF.PRCO" },
              title: "Produttori",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "brokers.index1" },
              title: "Collaborazioni orizzontali",
              menu_voice: "module.PROF",
            },
            {
              href: { name: "insurer_participants.index" },
              title: "Compagnie Coass",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "module.PROF.CONF" },
              title: "Mappatura Importatori",
              menu_voice: "module.PROF.CONF",
            },
            {
              href: { name: "users.index" },
              title: "Profilazione Utenti",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "book_tags.index" },
              title: "Codici Documenti Contabili",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "agency_setups.index" },
              title: "Anagrafica Agenzia",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "attributes.index" },
              title: "Campi Aggiuntivi",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "treasury-cash-reference" },
              title: "Conti e Causali Cont. Giornaliera",
              menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "emessage.index" },
              title: "eMessage",
              // menu_voice: "module.PROF",
              hidden: false,
            },
            {
              href: { name: "agency_setups.edit2" },
              title: "Impostazioni",
              icon: {
                element: "b-icon",
                attributes: {
                  icon: "gear",
                  style: "width: 20px; height: 20px;",
                  title: "Impostazioni",
                },
              },
            },
          ],
        },
        {
          href: { name: "importers.index" },
          title: "Importatori",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "file-arrow-down",
              style: "width: 20px; height: 20px;",
              title: "Import",
            },
          },
          disabled: false,
          menu_voice: "module.IMP",
          /* child: [
            {
              href: { name: "importers.index" },
              title: "Importatori",
              menu_voice: "module.IMP",
            },
          ],*/
        },
        {
          title: "Analisi",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "file-bar-graph",
              style: "width: 20px; height: 20px;",
              title: "Analisi",
            },
          },
          disabled: false,
          menu_voice: "module.ANAY",
          menu_permissions: [
            {
              resource: "analyses",
              verb: "index",
            },
          ],
          child: [
            {
              href: { name: "analysis.index" },
              title: "Analisi",
              menu_voice: "module.ANAY",
            },
            {
              href: { name: "analysis_result.index" },
              title: "Risultati",
              menu_voice: "module.ANAY",
            },
            {
              href: { name: "analysis_data_crossing.index" },
              title: "Incrocio Dati",
              menu_voice: "module.ANAY",
            },
          ],
        },
        {
          href: { name: "sepa.index" },
          title: "SEPA",
          icon: {
            element: "span",
            class: "sepa-icon",
            attributes: {
              style: "width: 20px; height: 20px;",
              title: "SEPA",
            },
          },
          disabled: false,
          menu_voice: "module.PROF.SEPA",
          /*child: [
            {
              href: { name: "sepa.index" },
              title: "Gestione SEPA",
              menu_voice: "module.PROF.SEPA",
            },
          ],*/
        },
        {
          href: { name: "fattutto.index" },
          title: "Fattutto",
          icon: {
            element: "span",
            class: "fattutto-icon",
            attributes: {
              style: "width: 20px; height: 20px;",
              title: "Fattutto",
            },
          },
          disabled: false,
          menu_voice: "module.FATT",
          menu_permissions: [
            {
              resource: "fattutto",
              verb: "send",
            },
            {
              resource: "fattutto",
              verb: "config",
            },
          ],
          /*child: [
            {
              href: { name: "sepa.index" },
              title: "Gestione SEPA",
              menu_voice: "module.PROF.SEPA",
            },
          ],*/
        },
        {
          href: { name: "report.index" },
          title: "Report",
          icon: {
            element: "b-icon",
            attributes: {
              variant: "light",
              icon: "file-earmark-spreadsheet",
              style: "width: 20px; height: 20px;",
              title: "Report",
            },
          },
          disabled: false,
          menu_voice: "module.REPORT",
          /*child: [
            {
              href: { name: "report.index" },
              title: "Gestione Report",
              menu_voice: "module.PFOLIO.REPG",
            },
          ],*/
        },
      ],
    };
  },
  computed: {
    ...mapGetters("sidebar", ["isNavClose"]),
    ...mapGetters("login_data", ["isLoginData"]),
    ...mapGetters("auth", ["isLogged", "permissions"]),
    // isAdmin() {
    //   let adminRoles = ["admin-agenzia", "super-admin", "admin-assistenza"];
    //   for (let i = 0; i < adminRoles.length; i++) {
    //     if (this.getRoles().includes(adminRoles[i])) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    logo() {
      return this.isNavClose
        ? `url(${require("@/assets/logo_collapsed.png")})`
        : `url(${require("@/assets/logo_expanded.png")})`;
    },
    logoBckSize() {
      return this.isNavClose ? "50px" : "240px";
    },
    w() {
      return this.isNavClose ? "50px" : "240px";
    },
    h() {
      return "85px";
    },
    cssProps() {
      return {
        backgroundImage: this.logo,
        "background-repeat": "no-repeat",
        "background-size": this.logoBckSize,
        width: this.w,
        height: this.h,
      };
    },
    // cliVer() {
    //   return process.env.VUE_APP_VERSION || "0";
    // },
  },
  methods: {
    // onItemClick(event, item, node) {
    /* @item-click="onItemClick" */
    //   console.log(event);
    //   console.log(item);
    //   console.log(node);
    //   setTimeout(() => {
    //     debugger;
    //   }, 50);
    // },
    isAdmin,
    canModule,
    ...mapActions("sidebar", ["saveNavbar"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapActions("tabs", { removeAllTabs: "removeAll" }),
    ...mapGetters("auth", {
      getRoles: "roles",
    }),
    // enableModule(code) {
    //   return this.permissions.includes(`${code}`);
    // },
    // enableResource(resource, verb = "index") {
    //   return this.permissions.includes(`resources.${resource}.${verb}`);
    // },
    setSubMenu(menu_voices) {
      menu_voices.child.forEach((sub_menu) => {
        let isHidden = false;
        switch (sub_menu.href.name) {
          case "users.index":
            isHidden = this.isAdmin ? false : true;
            break;
          case "bank_accounts.index":
            isHidden = canModule("module.PROF.SEPA") ? false : true;
            break;
          default:
            console.log("No submenu entry was found.");
        }
        this.$set(sub_menu, "hidden", isHidden);
      });
    },
    setUserSubMenu(menu_voices, value) {
      menu_voices.child.forEach((sub_menu) => {
        if (sub_menu.title === "Utenti") {
          this.$set(sub_menu, "hidden", value);
        }
      });
    },
    setChildren(menu_item) {
      const mode = process.env.NODE_ENV;
      if (!menu_item.child) return;
      menu_item.child.forEach((child) => {
        if (child.hideWhen && child.hideWhen.includes(mode)) {
          this.$set(child, "hidden", true);
        }
      });
    },
    skipModule(menu_item) {
      const mode = process.env.NODE_ENV;
      if (!menu_item.hideWhen) return false;
      return menu_item.hideWhen.includes(mode) ? true : false;
    },
    // atLeastOneEnableResource(permissions) {
    atLeastOneCanVerb(permissions) {
      let ret = permissions.length ? false : true;
      for (const p of permissions) {
        // ret ||= this.enableResource(p.resource, p.verb);
        ret ||= canVerb(p.resource, p.verb);
      }
      return ret;
    },
  },
  watch: {
    "$store.state.auth.permissions": {
      handler() {
        this.final_menu = [];
        // Object(this.menu).forEach((i) => {
        for (const i of this.menu) {
          if (i.menu_voice === "test" && process.env.VUE_APP_TEST == 1) {
            this.final_menu.push(i);
            continue;
          }
          if (i.menu_voice === "module.PROF") {
            if (!this.isAdmin()) {
              this.setUserSubMenu(i, true);
            } else {
              this.setUserSubMenu(i, false);
            }
          }
          if (!i.menu_voice) {
            this.final_menu.push(i);
          } else if (
            // (this.enableModule(i.menu_voice) ||
            (canModule(i.menu_voice) ||
              // this.enableResource(i.menu_voice)) &&
              canVerb(i.menu_voice, "index")) &&
            this.atLeastOneCanVerb(i.menu_permissions || [])
          ) {
            if (this.skipModule(i)) continue;
            this.setChildren(i);
            this.final_menu.push(i);
          } else {
            if (i.menu_voice === "module.REPORT") {
              if (canVerb("reports", "index")) {
                this.final_menu.push(i);
              }
            }
          }
          if (i.menu_voice === "module.PROF" && !this.isAdmin())
            this.final_menu.pop();
        }
      },
    },
    // TODO Aggiorno in maniera dinamica la lista delle voci di menù
    // "$store.state.sidebar.locked": {
    //   handler() {
    //     if (this.$store.state.sidebar.locked) {
    //       this.menu.forEach((element) => this.$set(element, "disabled", true));
    //     } else {
    //       this.menu.forEach((element) => this.$set(element, "disabled", false));
    //     }
    //   },
    // },
  },
  beforeMount() {
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
  },
};
</script>
<style lang="scss" scoped>
// :deep(.version) {
//   position: absolute;
//   top: 2px;
//   left: 130px;
//   font-size: smaller;
// }
:deep(.sepa-icon) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24' width='24'%3E%3Cpath d='M12 22.75C6.072 22.75 1.25 17.928 1.25 12S6.072 1.25 12 1.25c.723 0 1.446.072 2.149.215a.75.75 0 0 1 .586.884.75.75 0 0 1-.885.585c-.604-.123-1.228-.185-1.851-.185C6.899 2.75 2.75 6.899 2.75 12s4.149 9.25 9.25 9.25 9.25-4.149 9.25-9.25a9.23 9.23 0 0 0-.186-1.851.751.751 0 1 1 1.471-.299c.143.703.215 1.426.215 2.149 0 5.928-4.822 10.75-10.75 10.75zM22.745 6.489c-.012-.837-.055-2.634-.234-3.649h-.001a1.65 1.65 0 0 0-.368-.767c-.023-.033-.037-.071-.066-.101a1.71 1.71 0 0 0-.916-.484h0c-1.015-.18-2.812-.223-3.649-.234a.75.75 0 0 0-.02 1.5l2.569.159-3.087 3.053a.75.75 0 1 0 1.056 1.066l3.109-3.074.108 2.547h0c.006.414.348.75.762.744a.75.75 0 0 0 .739-.76h0zm-9.017 10.226c-1.79-.069-3.327-1.231-3.885-2.911h-.979c-.377 0-.684-.306-.684-.683s.307-.684.684-.684h.681l-.021-.434.021-.437h-.681c-.377 0-.684-.307-.684-.684s.307-.684.684-.684h.978a4.25 4.25 0 0 1 3.876-2.911h.001c.602 0 1.194.141 1.722.408.163.081.285.222.342.395a.68.68 0 0 1-.648.901c-.107 0-.209-.024-.305-.072-.342-.172-.723-.263-1.103-.263a2.82 2.82 0 0 0-2.398 1.543h2.015c.377 0 .683.307.683.684s-.306.684-.683.684h-2.426a3.73 3.73 0 0 0 0 .871h1.978c.377 0 .684.307.684.684s-.307.683-.684.683H11.33c.461.907 1.373 1.501 2.408 1.544.372 0 .753-.091 1.093-.263.096-.048.201-.073.306-.073a.68.68 0 0 1 .612.379c.082.163.095.349.037.522a.68.68 0 0 1-.343.396 3.81 3.81 0 0 1-1.707.407h-.006z' fill='gray'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px;
}
:deep(.fattutto-icon) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 110.963'%3E%3Cpath d='M145.973 53.242l-2.494-1.639-.157-3.007C142.05 24.262 124.853 4.773 100.97.598 98.535.172 90.277-.163 88.271.083c-15.844 1.94-28.316 9.018-36.745 20.854l-1.498 2.103-3.931-.138c-21.994-.775-40.355 13.359-45.08 34.7-5.708 25.787 13.296 51.286 39.555 53.072 7.305.497 89.091.308 92.589-.214 28.429-4.24 36.766-41.475 12.811-57.218zm-12.922 47.392c-3.011.621-90.833.69-94.448.073-21.971-3.744-34.807-26.854-26.174-47.12 6.688-15.7 22.926-23.883 39.884-20.099 1.428.319 2.626.546 2.662.506s.606-1.052 1.267-2.247c8.852-16.019 25.297-24.323 42.783-21.603 16.024 2.493 29.348 14.654 33.295 30.392 1.264 5.041 1.429 7.9.952 16.559l-.041.75 2.871 1.203c9.248 3.876 14.082 10.939 14.046 20.52-.039 10.455-6.952 18.973-17.098 21.067zM101.475 48.45H68.429l-1.208 13.158h8.111l-7.507 27.308h17.127l5.824-27.351h9.318l1.381-13.115zm2.329-8.24l2.071-14.042c-5.436-1.747-11.26-2.33-16.436-.841-6.625 1.905-8.777 12.763-9.512 17.86h16.695c.777-6.989 7.183-2.977 7.183-2.977z' fill='gray'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center center;
}
</style>
